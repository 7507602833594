/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MasterDataItem } from '../models/MasterDataItem';
import type { NiceClassesQuery } from '../models/NiceClassesQuery';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class NiceClassesService {
    /**
     * Gets all known nice classes and provides the localized description specified by the language code inside of the HTTP header or the URL parameter 'culture'.
     * @param queryDescription
     * @param queryShortDescription
     * @returns MasterDataItem Success
     * @throws ApiError
     */
    public static getNiceClasses(
        queryDescription?: string,
        queryShortDescription?: string,
    ): CancelablePromise<Array<MasterDataItem>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/NiceClasses',
            query: {
                'queryDescription': queryDescription,
                'queryShortDescription': queryShortDescription,
            },
        });
    }
    /**
     * Adds or updates a nice class in the data storage.
     * @param requestBody The new or existing nice class.
     * @returns MasterDataItem Success
     * @throws ApiError
     */
    public static upsertNiceClass(
        requestBody?: MasterDataItem,
    ): CancelablePromise<MasterDataItem> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/NiceClasses',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Queries all known nice classes by any semantic text and provides the localized description specified by the language code inside of the HTTP header or the URL parameter 'culture'.
     * @param requestBody The query parameters.
     * @returns MasterDataItem Success
     * @throws ApiError
     */
    public static executeNiceClassesQuery(
        requestBody?: NiceClassesQuery,
    ): CancelablePromise<Array<MasterDataItem>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/NiceClasses/query',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Gets the nice class by its number and provides the localized description specified by the language code inside of the HTTP header or the URL parameter 'culture'.
     * @param key The number as string.
     * @returns MasterDataItem Success
     * @throws ApiError
     */
    public static getNiceClassByKey(
        key: string,
    ): CancelablePromise<MasterDataItem> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/NiceClasses/{key}',
            path: {
                'key': key,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }
    /**
     * Deletes the nice class by its key.
     * @param key The number as string.
     * @returns any Success
     * @throws ApiError
     */
    public static deleteNiceClass(
        key: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/NiceClasses/{key}',
            path: {
                'key': key,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
}
