/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BrandExclusion } from '../models/BrandExclusion';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class BrandExclusionsService {
    /**
     * Gets the brand exclusions for the default account of the currently logged in user.
     * @returns BrandExclusion Success
     * @throws ApiError
     */
    public static getBrandExclusionsCurrentUserDefaultAccount(): CancelablePromise<Array<BrandExclusion>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user/account/BrandExclusions',
            errors: {
                401: `Unauthorized`,
            },
        });
    }
    /**
     * Creates a brand exclusion for the default account of the currently logged in user.
     * @param requestBody The new brand exclusion.
     * @returns BrandExclusion Success
     * @throws ApiError
     */
    public static createBrandExclusionCurrentUserDefaultAccount(
        requestBody?: BrandExclusion,
    ): CancelablePromise<BrandExclusion> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/user/account/BrandExclusions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }
    /**
     * Gets the brand exclusion for the default account of the currently logged in user.
     * @param id
     * @returns BrandExclusion Success
     * @throws ApiError
     */
    public static getBrandExclusionCurrentUserDefaultAccount(
        id: string,
    ): CancelablePromise<BrandExclusion> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user/account/BrandExclusions/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Deletes the brand exclusion for the default account of the currently logged in user.
     * @param id The id.
     * @returns any Success
     * @throws ApiError
     */
    public static deleteBrandExclusionCurrentUserDefaultAccount(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/user/account/BrandExclusions/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
}
