/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Account } from '../models/Account';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AccountsService {
    /**
     * Gets the accounts of the currently logged in user.
     * @returns Account Success
     * @throws ApiError
     */
    public static getCurrentUserAccounts(): CancelablePromise<Array<Account>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user/Accounts',
            errors: {
                401: `Unauthorized`,
            },
        });
    }
    /**
     * Gets the accounts of a user.
     * @param id
     * @returns Account Success
     * @throws ApiError
     */
    public static getUserAccounts(
        id: number,
    ): CancelablePromise<Array<Account>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/users/{id}/Accounts',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Gets all existing accounts.
     * @returns Account Success
     * @throws ApiError
     */
    public static getAccounts(): CancelablePromise<Array<Account>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Accounts',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Gets or creates the default account of the currently logged in user.
     * @returns Account Success
     * @throws ApiError
     */
    public static getCurrentUserDefaultAccount(): CancelablePromise<Account> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user/account',
            errors: {
                401: `Unauthorized`,
            },
        });
    }
    /**
     * Gets the account by id.
     * @param id
     * @returns Account Success
     * @throws ApiError
     */
    public static getAccount(
        id: number,
    ): CancelablePromise<Account> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Accounts/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
}
