/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompletionPrompt } from '../models/CompletionPrompt';
import type { CompletionResult } from '../models/CompletionResult';
import type { ScoringPrompt } from '../models/ScoringPrompt';
import type { ScoringResult } from '../models/ScoringResult';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AiService {
    /**
     * Executes an AI completion prompt.
     * @param requestBody The completion prompts.
     * @returns CompletionResult Success
     * @throws ApiError
     */
    public static executeCompletion(
        requestBody?: CompletionPrompt,
    ): CancelablePromise<CompletionResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Ai/completions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server Error`,
            },
        });
    }
    /**
     * Executes an AI scoring prompt.
     * @param requestBody The scoring prompt.
     * @returns ScoringResult Success
     * @throws ApiError
     */
    public static executeScoring(
        requestBody?: ScoringPrompt,
    ): CancelablePromise<ScoringResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Ai/scorings',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server Error`,
            },
        });
    }
}
