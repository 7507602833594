/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AutoCompleteOptions } from '../models/AutoCompleteOptions';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class BrandAutocompletesService {
    /**
     * Autocompletes brand fields.
     * @param text The input text.
     * @param requestBody The options how to perform autocomplete.
     * @returns string Success
     * @throws ApiError
     */
    public static autocompleteBrandNames(
        text: string,
        requestBody?: AutoCompleteOptions,
    ): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/BrandAutocompletes/{text}',
            path: {
                'text': text,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
