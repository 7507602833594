/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BrandQueryHistoryItem } from '../models/BrandQueryHistoryItem';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class BrandQueryHistoryService {
    /**
     * Gets a brand query history item.
     * @param id The brand query history id.
     * @returns BrandQueryHistoryItem Success
     * @throws ApiError
     */
    public static getBrandQueryHistoryItem(
        id: string,
    ): CancelablePromise<BrandQueryHistoryItem> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/BrandQueryHistory/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Gets the brand query history for a brand query.
     * @param id The brand query id.
     * @param count Number of latest history items.
     * @returns BrandQueryHistoryItem Success
     * @throws ApiError
     */
    public static getBrandQueryHistory(
        id: number,
        count?: number,
    ): CancelablePromise<Array<BrandQueryHistoryItem>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/BrandQueries/{id}/history',
            path: {
                'id': id,
            },
            query: {
                'count': count,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
}
