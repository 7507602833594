import { render, staticRenderFns } from "./FeedbackSnackbar.vue?vue&type=template&id=85b4230a&scoped=true&"
import script from "./FeedbackSnackbar.vue?vue&type=script&lang=ts&"
export * from "./FeedbackSnackbar.vue?vue&type=script&lang=ts&"
import style0 from "./FeedbackSnackbar.vue?vue&type=style&index=0&id=85b4230a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85b4230a",
  null
  
)

export default component.exports