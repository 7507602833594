





























import { Component, Prop } from 'vue-property-decorator';
import FeedbackBase from './FeedbackBase';

@Component({})
export default class FeedbackSnackbar extends FeedbackBase {
    // Member:
        
    // Properties:
    @Prop({ required: false, type: Number, default: -1 })
    public timeout!: number;

    // Getter & Setter:
    public get vertical(): boolean {
      return this.$vuetify.breakpoint.smAndDown || this.showComment;
    }

    public get showComment() {
      return this.rating !== null && this.rating < 5;
    }
    
    // Watcher:

    // Lifecycle Methods:

    // Methods:
}
