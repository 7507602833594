/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PayPalSubscriptionApproval } from '../models/PayPalSubscriptionApproval';
import type { PayPalSubscriptionCancellation } from '../models/PayPalSubscriptionCancellation';
import type { PayPalSubscriptionUpdate } from '../models/PayPalSubscriptionUpdate';
import type { Subscription } from '../models/Subscription';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SubscriptionsService {
    /**
     * Gets the subscription by id.
     * @param id
     * @returns Subscription Success
     * @throws ApiError
     */
    public static getSubscription(
        id: number,
    ): CancelablePromise<Subscription> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/subscriptions/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Gets the subscriptions for the current user's default account.
     * @returns Subscription Success
     * @throws ApiError
     */
    public static getSubscriptionsCurrentUserDefaultAccount(): CancelablePromise<Array<Subscription>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user/account/subscriptions',
            errors: {
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }
    /**
     * Gets the current active subscription for the current user's default account or the default subscription for anonymous users.
     * @returns Subscription Success
     * @throws ApiError
     */
    public static getActiveSubscriptionCurrentUserDefaultAccount(): CancelablePromise<Subscription> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user/account/subscription',
            errors: {
                500: `Server Error`,
            },
        });
    }
    /**
     * Creates a subscription based on a PayPal approval for the current user's default account and sets it as the active subscription.
     * @param requestBody The approved PayPal subscription and braendz subscription details.
     * @returns Subscription Success
     * @throws ApiError
     */
    public static createPayPalSubscriptionCurrentUserDefaultAccount(
        requestBody?: PayPalSubscriptionApproval,
    ): CancelablePromise<Subscription> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user/account/subscription/paypal',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }
    /**
     * Cancelles the PayPal subscription for the current user's default account.
     * @param id The PayPal subscription id.
     * @param requestBody The cancellation details.
     * @returns any Success
     * @throws ApiError
     */
    public static cancelPayPalSubscription(
        id: string,
        requestBody?: PayPalSubscriptionCancellation,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/subscriptions/paypal/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }
    /**
     * Updates a PayPal subscription for the current user's default account and sets it as the active subscription.
     * @param id The PayPal subscription id.
     * @param requestBody The braendz subscription update details.
     * @returns Subscription Success
     * @throws ApiError
     */
    public static updatePayPalSubscription(
        id: string,
        requestBody?: PayPalSubscriptionUpdate,
    ): CancelablePromise<Subscription> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/subscriptions/paypal/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }
}
