/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Product } from '../models/Product';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ProductsService {
    /**
     * Gets the product catalog and provides the localized name and description specified by the language code inside of the HTTP header or the URL parameter 'culture'.
     * @param reload To force cache reloading.
     * @returns Product Success
     * @throws ApiError
     */
    public static getProducts(
        reload?: boolean,
    ): CancelablePromise<Array<Product>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Products',
            query: {
                'reload': reload,
            },
        });
    }
    /**
     * Gets the product by its key and provides the localized description specified by the language code inside of the HTTP header or the URL parameter 'culture'.
     * @param key The product's key.
     * @param reload To force cache reloading.
     * @returns Product Success
     * @throws ApiError
     */
    public static getProduct(
        key: string,
        reload?: boolean,
    ): CancelablePromise<Product> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Products/{key}',
            path: {
                'key': key,
            },
            query: {
                'reload': reload,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }
}
