import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import i18n from './i18n'
import VueSocialSharing from 'vue-social-sharing'
import { EnvPlugin } from './plugins/env'
import { AuthPlugin } from './plugins/auth'
import { EmitterPlugin } from './plugins/emitter'
import { ApiRequestOptions } from './api/braendz/core/ApiRequestOptions'
import { BraendzPlugin } from './plugins/braendz'
import { StatisticsPlugin } from './plugins/statistics'
import { ValidationPlugin } from './plugins/validation'
import { OpenAPI } from './api/braendz'
import { UtilPlugin } from './plugins/util'
import VueCookies from "vue-cookies-ts"
import VueGtag from "vue-gtag"
import VueMeta from 'vue-meta'
import VueApexCharts from 'vue-apexcharts'
import AOS from 'aos'
import 'aos/dist/aos.css';

// Configure Vue:
Vue.config.productionTip = false

// Set API URL from node.js environment config:
// Comment: If this doesn't work create plugin configuration for it (like auth-Plugin)
OpenAPI.BASE = process.env.VUE_APP_BRAENDZ_API_BASE_URL ?? "";
OpenAPI.WITH_CREDENTIALS = true;
OpenAPI.HEADERS = async (options: ApiRequestOptions) => {
    return { "Accept-Language": i18n.locale }
}


// Initialize Vue Plugins:
Vue.use(EnvPlugin);
Vue.use(VueSocialSharing);
Vue.use(AuthPlugin);
Vue.use(EmitterPlugin);
Vue.use(BraendzPlugin);
Vue.use(StatisticsPlugin);
Vue.use(ValidationPlugin);
Vue.use(UtilPlugin);
Vue.use(VueCookies);
Vue.use(VueGtag, { 
  config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID },
  enabled: false
}, router);
Vue.use(VueMeta);
Vue.use(VueApexCharts);

Vue.component('apexchart', VueApexCharts);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
  components: {
  },
  created () {
    AOS.init()
  },
}).$mount('#app')