import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';
import { BusyObject } from '@/models/Busy';
import { GlobalEventType } from '@/models/GlobalEventType';
import { FeedbackRequest, FeedbacksService } from '@/api/braendz';

@Component({
    components: {
    },
    model: {
        prop: "model",
        event: "visibilityChanged"
    }
})
export default class FeedbackBase extends Vue {
    // Constants

    // Member
    public visibility = false;
    public rating: number | null = null;
    public comment = "";
    public showNever = false;
    public area: string | null = null;
    public feedbackRequest = new BusyObject<FeedbackRequest>();
    public pageBlackList = ['Feedback']
        
    // Properties
    @Prop({ required: false })
    public model?: boolean;

    @Prop({ required: false })
    public defaultArea?: string;

    @Prop({ required: false })
    public autoActivationSeconds?: number;

    @Prop({ required: false, type: Number, default: 60 })
    public postponeOnCloseDays!: number;

    @Prop({ required: false, type: Number, default: 120 })
    public postponeOnSubmitDays!: number;

    // Getter & Setter
    public get visible(): boolean {
        return this.model ?? this.visibility;
    }
    public set visible(value: boolean) {
        this.visibility = value;
        if(!value) this.reset();
        this.$emit('visibilityChanged', value);
    }
    
    // Watcher:

    // Lifecycle Methods:
    public created(): void {
        this.$emitter.on(GlobalEventType.GetFeedback, (area?: string, delaySeconds?: number) => {
            this.area = area ?? null;

            if(delaySeconds) {
                this.setActivationTimer(delaySeconds);
            }
            else {
                this.show();
            }
        });
    }
    public mounted() {
        if(this.autoActivationSeconds) {
            this.setActivationTimer(this.autoActivationSeconds);
        }
    }

    // Methods:
    public show(): void {
        if(!this.isDisabled() && this.$route.name && !this.pageBlackList.includes(this.$route.name)) {
            this.visible = true;
        }
    }

    public rejectAndClose(): void {
        if(this.showNever) {
            this.disable(true);
        }
        else {
            const date = new Date();
            date.setDate(date.getDate() + this.postponeOnCloseDays);
            this.disable(true, date);
        }

        this.visible = false;
    }

    public submitAndClose(): void {
        this.feedbackRequest.create(async () => {
            const request = { 
                context: this.area ?? this.defaultArea ?? 'Common',
                rating: this.rating,
                comment: this.comment
            } as FeedbackRequest;
            await FeedbacksService.submitFeedback(request);
            return request;
        });

        if(this.showNever) {
            this.disable(true);
        }
        else {
            const date = new Date();
            date.setDate(date.getDate() + this.postponeOnSubmitDays);
            this.disable(true, date);
        }

        this.visible = false;
    }

    public disable(disabled: boolean, until?: string | number | Date) {
        this.$cookies.set('fb_disabled', disabled, { expires: until ?? Infinity });
    }

    public isDisabled(): boolean {
        const value = this.$cookies.get('fb_disabled');
        return typeof value === 'boolean' ? value 
            : typeof value === 'string' ? (value === 'true') 
            : false;
    }

    public setActivationTimer(seconds: number) {
        setTimeout(this.show, seconds * 1000);
    }

    public reset() {
        this.area = null;
        this.rating = null;
        this.comment = "";
        this.showNever = false;
    }
}