/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BrandBookmarkContainer } from '../models/BrandBookmarkContainer';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class BrandBookmarkContainersService {
    /**
     * Gets the brand bookmark containers for the default account of the currently logged in user.
     * @returns BrandBookmarkContainer Success
     * @throws ApiError
     */
    public static getBrandBookmarkContainersCurrentUserDefaultAccount(): CancelablePromise<Array<BrandBookmarkContainer>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user/account/brands/bookmarkcontainers',
            errors: {
                401: `Unauthorized`,
            },
        });
    }
    /**
     * Creates or updates a brand bookmark container for the default account of the currently logged in user.
     * @param requestBody The new or updated brand bookmark container.
     * @returns BrandBookmarkContainer Success
     * @throws ApiError
     */
    public static upsertBrandBookmarkContainerCurrentUserDefaultAccount(
        requestBody?: BrandBookmarkContainer,
    ): CancelablePromise<BrandBookmarkContainer> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/user/account/brands/bookmarkcontainers',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
                422: `Client Error`,
            },
        });
    }
    /**
     * Gets the brand bookmark container.
     * @param id The container id.
     * @returns BrandBookmarkContainer Success
     * @throws ApiError
     */
    public static getBrandBookmarkContainer(
        id: number,
    ): CancelablePromise<BrandBookmarkContainer> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/brands/bookmarkcontainers/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Deletes the brand bookmark container.
     * @param id The id.
     * @returns any Success
     * @throws ApiError
     */
    public static deleteBrandBookmarkContainer(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/brands/bookmarkcontainers/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
}
