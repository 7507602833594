/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PayPalConfigurations } from '../models/PayPalConfigurations';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ConfigurationsService {
    /**
     * Gets the client configurations for PayPal.
     * @returns PayPalConfigurations Success
     * @throws ApiError
     */
    public static getPayPalConfigurations(): CancelablePromise<PayPalConfigurations> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Configurations/PayPal',
        });
    }
}
